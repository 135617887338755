<!--
	作者：jyl
	时间：2017-07-26
	描述：商户信息管理页面
-->
<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"> -->
    <!--<el-breadcrumb separator="/">
                <el-breadcrumb-item>商户管理</el-breadcrumb-item>
                <el-breadcrumb-item>商户信息管理</el-breadcrumb-item>
            </el-breadcrumb>-->
    <!-- </div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('searchModule.Rule_Name')">
            <el-input
              size="12"
              :maxlength="50"
              v-model.trim="formInline.feeName"
              placeholder="输入规则名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="规则编码">
            <el-input
              size="12"
              :maxlength="50"
              v-model.trim="formInline.feeCode"
              placeholder="输入规则编码"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_operator')">
            <el-select v-model.trim="formInline.operationId" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <el-button
              type="primary"
              v-if="$route.meta.authority.button.add"
              icon="el-icon-plus"
              @click="$router.push('/continueRule-ae')"
              >{{ $t('button.addto') }}</el-button
            >
            <!-- <el-dropdown @command="handleClick($event)">
                <el-button type="primary">
                  添加 <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="acs">ACS</el-dropdown-item>
                  <el-dropdown-item command="xia">厦门</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
            <!-- <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">分时段计费</el-dropdown-item>
                <el-dropdown-item command="b">延时计费</el-dropdown-item>
              </el-dropdown-menu> -->
          </el-form-item>
          <!-- <el-button type="primary" v-if='$route.meta.authority == 1' icon="el-icon-plus" @click="$router.push({path: '/addContinueRule'})">{{ $t('button.addto') }}</el-button> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%; font-size: 12px"
        >
          <!--<el-table-column type="index" :index='indexMethod':label="$t('list.index')" width="70" align='center'></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="140"
            align="center"
            v-if="
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.view ||
              $route.meta.authority.button.banding
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="primary" size="small"> 操作 </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="b"
                    v-show="$route.meta.authority.button.edit && scope.row.templateId != 104"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="c" v-show="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item command="d" v-show="$route.meta.authority.button.banding"
                    >绑定车场</el-dropdown-item
                  >
                  <el-dropdown-item command="e" v-show="$route.meta.authority.button.banding"
                    >模拟计算</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-button type="text" @click="$router.push({path: '/seeRule', query: scope.row})">查看</el-button>
              <el-button type="text" @click="editHandle(scope.row)">{{ $t('button.edit') }}</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="模拟计算器"
      :visible.sync="dialogTableVisible"
      @close="consoleCalculation"
      style="width: 1300px; margin-left: calc(50% - 650px); margin-top: 5%"
    >
      <el-form :model="form">
        <el-form-item :label="$t('searchModule.Vehicle_ype')">
          <el-select v-model.trim="vehicleType" filterable size="15">
            <!-- <el-option label="全部"
                         value=""></el-option> -->
            <el-option
              :label="value.name"
              :value="value.value"
              :key="value.value"
              v-for="value in vehicleTypeList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入出场时间">
          <timeRangePick
            @timeChange="timeChange"
            ref="timeRangePicker"
            :defalutDate="defalutDate"
          />
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="calculation()"
            style="margin-left: 1px"
            :loading="loading"
            >模拟计算</el-button
          >
        </el-form-item>
      </el-form>
      <el-card class="box-card" v-show="vehicleTypeStates">
        <div class="el-icon-warning">计算结果</div>
        本次停车产生费用{{ calculationValue | moneyHandling("元") }}
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import { dateFormat } from "@/common/js/public.js";
import timeRangePick from "@/components/timePicker/timePicker";
export default {
  name: "chargeRules",
  data() {
    return {
      tenantList: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      berthList: [],
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "",
        },
        {
          prop: "feeName",
          label: this.$t("list.Rule_Name"),
          width: "",
        },
        {
          prop: "feeCode",
          label: "规则编码",
          width: "",
        },
        {
          prop: "operationManagerName",
          label: "创建人",
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
          width: "",
        },
        {
          prop: "description",
          label: "描述",
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        feeName: "",
        feeCode: "",
        operationId: "",
        startTime: "",
        endTime: "",
      },
      defalutDate: [],
      form: {},
      vehicleType: 0,
      vehicleTypeList: [
        { name: "普通车", value: 0 },
        { name: "新能源", value: 1 },
        { name: "残疾人车", value: 3 },
      ],
      dialogTableVisible: false,
      feeId: "",
      calculationValue: 0,
      vehicleTypeStates: false,
    };
  },
  methods: {
    calculation() {
      console.log("time1", this.formInline.startTime);
      console.log("endTime", this.formInline.endTime);
      if (this.formInline.startTime && this.formInline.endTime) {
        this.getcalculationVlaue();
      } else {
        this.$alert("请选择时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    consoleCalculation() {
      this.vehicleTypeStates = false;
    },
    getcalculationVlaue() {
      this.$axios
        .get("/acb/2.0/charge/simpleVirtualCharge", {
          data: {
            feeId: this.feeId,
            virtualCarType: this.vehicleType,
            entry: this.formInline.startTime,
            exit: this.formInline.endTime,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.calculationValue = res.value;
            this.vehicleTypeStates = true;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    handleClick(event) {
      if (event === "acs") {
        this.$router.push("/addContinueRule");
      } else {
        this.$router.push("/addContinueRule_xia");
      }
    },
    handleCommand(cmd, data) {
      if (cmd == "c") {
        if (data.templateId === 120) {
          this.$router.push({ path: "/seeContinueRule_xia", query: data });
        } else if (data.templateId === 160) {
          this.$router.push({ path: "/seeContinueRuleNew", query: data });
        } else {
          this.$router.push({ path: "/seeContinueRule", query: data });
        }
      }
      if (cmd == "b") {
        this.$router.push({
          path: "/continueRule-ae",
          query: data,
        });
        // this.editHandle(data);
      }
      if (cmd == "d") {
        this.$router.push({
          path: "/setContinueRule",
          query: data,
        });
      }
      if (cmd == "e") {
        this.dialogTableVisible = true;
        this.feeId = data.feeId;
      }
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    editHandle(data) {
      if (data.templateId === 120) {
        this.$router.push({ path: "/addContinueRule_xia", query: data });
      } else if (data.templateId === 150) {
        this.$router.push({ path: "/addContinueRule1", query: data });
      } else {
        this.$router.push({ path: "/addContinueRule", query: data });
      }
    },
    addHandle(cmd) {
      //      if (cmd == "a") {
      //        this.$router.push("/addTimePeriod");
      //      }
      //      if (cmd == "b") {
      //        this.$router.push("/addContinueRule");
      //      }
      this.$router.push("/addContinueRule");
    },
    indexMethod(index) {
      return index + 1;
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    showLog(data) {
      if (data && data.length === 2) {
        let startExpiryDate = dateFormat(data[0], "yyyy-MM-dd");
        let endExpiryDate = dateFormat(data[1], "yyyy-MM-dd");
        this.formInline.startExpiryDate = startExpiryDate;
        this.formInline.endExpiryDate = endExpiryDate;
      } else {
        this.formInline.startExpiryDate = "";
        this.formInline.endExpiryDate = "";
      }
    },
    showDetail(index, obj) {
      this.$router.push({
        path: "/merchantManager-AE",
        query: obj,
      });
    },
    // 管理用户
    managerUser(index, obj) {
      this.$router.push({
        path: "/managerUser",
        query: obj,
      });
    },
    // 搜索
    searchData() {
      this.loading = true;
      //  this.formInline = clearNull(this.formInline);
      this.$axios
        .get("/acb/2.0/fee/list", {
          data: {
            ...this.formInline,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {
    timeRangePick,
  },
  created() {
    this.getTenantList();
    this.searchData();
  },
  activated() {
    this.searchData();
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
